/**** Plan de site ****/
#plan-site .bloc_filtre ul {
  padding-left:15px;
}
#plan-site .bloc_filtre > ul {
  column-gap: 40px;
  column-rule: 1px dotted #ccc;
  column-width: 300px;
  padding-left:10px;
  list-style-type: none;
}
#plan-site .bloc_filtre > ul > li {
  padding: 10px 0;
}
#plan-site .bloc_filtre > ul > li > .m_event {
  color: $color2;
  font-size:18px;
  margin-bottom:5px;
  display:inline-block;
}
.menu-sitemap2 > li {
  border-bottom: 1px dotted #ccc;
}
#plan-site .bloc_filtre > ul .icon {
  font-size:25px;
  vertical-align: middle;
  margin-right:5px;
  color: $color2;

}

#plan-site .bloc_filtre {
  border: 1px solid #e7e7e7;
  padding: 1.2rem 1rem;
  margin-bottom: 2rem;
}