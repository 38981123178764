#catalog,
#fiche{
  background-repeat: no-repeat;
  background-position: right;
}

#listeproduit ul {
  padding: 0;
  margin: auto;
}


#listeproduit ul,
#listeproduit li {
  list-style-type: none;
}

.pres_prod {
  padding: 15px;
}

#listeproduit > ul > li,
.pres_prod > li {
  border:1px solid #ddd;
  border-right: none;
  list-style-type: none;
}

.pres_prod li:nth-child(6n),
.pres_prod li:last-child {
  border-right: solid 1px #ddd !important;
}


#liste {
  position : relative;
}

#liste:after {
  content : '';
  transition : background 0.2s ease-in-out;
  background : none;
}

#liste.loading:after {
  position : absolute;
  top : 0;
  left : 0;
  right : 0;
  bottom : -10px;
  z-index : 100;
}

#loading-catalogue {
  position : absolute;
  top : 200px;
  left : calc(50% - 8px);
  width : 15px;
  height : 15px;
  z-index : 101;
  opacity : 0;
  visibility : hidden;

  transition : opacity .2s ease-in-out;

  .loading {
    opacity : 1;
    visibility : visible;
  }

}


/* Fiche produit */
.fiche_produit {

    background-color: #fff;
    text-align: center;
    overflow: hidden;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .title_produit {
    font-size: 1.8rem;
    font-weight: normal;
    text-align: left;
    color: $color2;
    text-transform: none;
    line-height: 1.3;
    height: 50px;
    /*-ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;*/

    a {
      color: #474646;
    }
    a:hover {
      color: $color3;
    }
  }

  .abs_c .product_img_link img {
    position: relative;
    overflow: hidden;
    max-width: 100%;
    max-height: 100%;
    align-items: center;
    height: 100%;
    object-fit: contain;
  }

  .marque_produit {
    font-size: 12px;
    font-weight: normal;
    text-align: left;
    line-height: 1;
    margin-top: 1.4rem;
    height: 40px;
    overflow: hidden;
  }
  .marque_produit a {
    color:#999;
  }
  .marque_produit a:hover,
  .fiche_produit .marque_produit a:focus {
    color: $color3;
  }
  .accroche_produit {
    font-size: 18px;
    line-height: 1;
    margin-top: 0.4rem;
    height: 38px;
    overflow: hidden;
  }
  .prix_produit{
    font-size: 2rem;
    color: $color2;
    font-weight: bold;
  }
  .produit_infos {
    position: relative;
    z-index: 150;
  }
  .hover_block{
    height: 45px;
    position: absolute;
    bottom: -44px;
    left: 0;
    right: 0;
    background-color: #EEEEEE;
    z-index: 2;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
  }
  .hover_block::before{
    content: "";
    background-color: #EEEEEE;
    z-index: 0;
    border-radius: 50%;
    display: block;
    height: 70px;
    width: 70px;
    position: absolute;
    top: -13px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  &:hover .hover_block{
    bottom: 0;
  }

  .hover_block a,
  .hover_block .lien-cache {
    line-height: 45px;
  }
  .hover_block .detail_prod_text{
    margin-right: 28px;
  }
  .hover_block .icon{
    font-size: 25px;
  }

  .add_cart {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #34CBA2;
    color: #34CBA2;
    font-size: 18px;
    cursor: pointer;
    margin-top: 2px;
    display: inline-block;
    padding: 0;
    .icon {
      font-size: 18px;
    }
  }
  .add_cart:hover {
    background-color: #34CBA2;
    border: 1px solid #34CBA2;
    color: #fff;
  }
  .add_cart:focus {
    outline:0 !important;
  }
  .add_cart:hover {
    color: #fff;
  }

  /* Bouton add cart rupture */
  .add_cart.rupture {
    position: relative;
    background-color: #fff;
    border: 1px solid #c3c3c3;
    color: #c3c3c3;
  }
  .add_cart.rupture .text {
    position:absolute;
    bottom:54px;
    right:0;
    width:140px;

    max-height:0;
    overflow: hidden;
  }
  .add_cart.rupture:hover .text,
  .add_cart.rupture:focus .text {
    max-height:50px;
  }
  .add_cart.rupture .text .button-content {
    position:relative;
    font-size:12px;
    line-height: 1;
    border-radius: 5px;
    background: #698F20;
    color: #fff;
    display:block;
    margin-bottom:10px;
  }
  .add_cart.rupture .text .button-content {
    background: #999;
    color: #fff;
  }

  .add_cart.rupture .text .button-content span { display:inline-block; }
  .add_cart.rupture .text .button-content span:first-child { margin-top:5px; }
  .add_cart.button-no-member .text .button-content span:last-child,
  .add_cart.rupture .text .button-content span:last-child { margin-bottom:5px; }

  .add_cart.button-no-member .text .button-content:before,
  .add_cart.rupture .text .button-content:before {
    content:'';
    position:absolute;
    bottom:-10px;
    right:15px;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #688f20 transparent transparent transparent;
  }
  .add_cart.rupture .text .button-content:before {
    border-color: #999 transparent transparent transparent;
  }
/* FIN Bouton add cart rupture */


  .lien-cache {
    cursor: pointer;
  }
  .abs_c {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
  figure {
    margin: auto;
  }
  .img_responsive {
    position: relative;
    width: 100%;
    padding-top: 100%;
  }

}

/**** Menu rubrique colonne gauche ****/

#left_column #menurubrique {
  .h2 {
    font-size: 22px;
    text-transform: initial;
    font-weight: 700;
    margin: auto auto 0.3em auto;
    text-align: left;
  }
  .h2 a {
    color: $color2;
  }
}


#menurubrique .bloc_filtre {
  border: 1px solid #E7E7E7;
  padding: 1.2rem 1rem;
  margin-bottom: 2rem;
}

#rubrique {
  margin:auto;
  h2
  {
    color:#444;
    text-transform:uppercase;
    font-size:100%;
    font-weight:500;
  }
  .boite {
    border:1px solid #e1e1e1;

    -moz-box-shadow: 0px 0px 8px #d3d3d3;
    -webkit-box-shadow:  0px 0px 8px #d3d3d3;
    box-shadow:  0px 0px 8px #d3d3d3;
  }
  .boite15f  {
    padding: 0;
  }
  .h3.cat_title a {
    font-size: 14px;
    color: #474646;
    min-height: 10px;
    padding: 3px 8px;
  }
  a .icon-arrow_left{
    float: right;
  }
}


#rubrique ul,
#rubrique li {
  margin:0;
}

#rubrique ul {
  font-size: 95%;
  line-height: 1.4em;
  padding-left: 0;

  ul.ul_n3 .cat_title a {
    font-size: 14px;
    font-weight: normal;
  }
  li {
    margin:0 0;
    list-style-type: none;
  }
  a,
  .li_n2.current .li_n3 a {
    color: #444;
    text-decoration: none;
    display:block;


    -moz-border-radius:3px;
    -webkit-border-radius:3px;
    border-radius:3px;

    -o-transition: color .3s ease-in, background-color .5s ease-out;
    -webkit-transition: color .3s ease-in, background-color .5s ease-out;
    -moz-transition: color .3s ease-in, background-color .5s ease-out;
    transition: color .3s ease-in, background-color .5s ease-out;
  }
  .li_n2 {
    border-bottom: 2px dotted #eee;
  }
  a:hover,
  a:focus
  {
    color: #222;
    background-color:#ddd;

    background-position: 3px -0.68em;
  }
}


#rubrique .current2 a,
#rubrique .current2 a:hover {
  background-color:transparent;
  background-position: 3px -0.7em;
}


#rubrique .current2 .categorie, #rubrique .current2 .categorie:hover,
#rubrique .current2 .current.categorie, #rubrique .current2 .current.categorie:hover {
  position:relative;
  color: #555;/**/
  font-weight:bold;
  background-color:transparent;
}



#rubrique .famille a.current .icon-arrow_left {
  transform: rotate(-90deg);
}

#rubrique .current2 .pictodown, #rubrique .current2 .pictodown
{
  position:absolute;
  display:block;
  z-index:1;
  top:8px;
  right:-7px;
  width:6px;
  height:4px;

}

#rubrique .current3 .categorie, #rubrique .current3 .categorie:hover
{
  position:relative;
  color: #555;
  font-weight:bold;
  background:none;
}


#rubrique .famille a {
  background-position: 3px 0.60em;
  &a:hover {
    color: #222;
    background-color:#eee;
  }
}

#rubrique .famille .li_n3 > .cat_title > a.current, #rubrique  .famille .li_n3 > .cat_title  > a.current:hover {
  color: $color2;
}
#rubrique .famille .li_n2 > .cat_title  > a.current, #rubrique  .famille .li_n2 > .cat_title  > a.current:hover {
  color: $color2;
}

.f-cursor {
  position:relative;
  z-index: 1;
  cursor:pointer; }


/* menu rubrique niveau 3 */
#rubrique .li_n3 {
  padding: 0px 0px 0px 5px;
  a {
    background-position: 3px 0.60em;
  }
  a:hover {
    background-position: 3px -0.92em;
  }
}


/******** deplier rubrique *********/
#rubrique #dep {
  text-align:right;
  margin-top:-0.5em;
  a {
    display:inline-block;
    padding-right:12px;
    color:#777;
    -moz-box-shadow:none;
    -webkit-box-shadow:none;
    box-shadow:none;
  }
  a:hover {
    color:#0ad;
    -moz-box-shadow:none;
    -webkit-box-shadow:none;
    box-shadow:none;
  }
}

/* header filtre, tri */
.cat_header {
  margin: 0 auto 1em auto;
  color: #878787;

  .filtre_order {
    float : right;
    text-align: right;
    position: relative;
  }
  .nb_produit,
  .filtre_order .number_view,
  .filtre_order .order_view,
  .filtre_order .number_view label,
  .filtre_order .order_view label {
    display: inline-block;
    position: relative;
  }
  .filtre_order .number_view {
    margin-right:0.5em;
  }
  .nb_produit {
    margin-top:0.2em;
  }
  .filtre_order .number_view,
  .filtre_order .order_view {
    margin-bottom:0.3em;
  }
  .filtre_order .number_view { margin-right:0.5em; }
  .nb_produit { margin-top:0.2em; }

  .filtre_order .number_view,
  .filtre_order .order_view {
    margin-bottom:0.3em;
  }
  .filtre_order .ui-menu .ui-menu-item-wrapper {
    font-weight: 400;
  }
  .separateur{
    margin: 0 5px;
  }
}

.filt_title{
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
}


@media (max-width: $breakpoint-lg) {
  #listeproduit > ul > li, .pres_prod > li {
    border:1px solid #ddd ;
  }
}

@media (max-width: $breakpoint-sm) {
  #listeproduit > ul > li, .pres_prod > li {
    border-right:1px solid #ddd ;
  }
}

@media (max-width: $breakpoint-xs) {
  .nb_produit,
  .filtre_order,
  #nb_article_par_page {
    font-size: 1.2rem;
  }
  #listeproduit > ul > li, .pres_prod > li {
    border-right:1px solid #ddd ;
  }
  .fiche_produit .add_cart {
    width: 42px;
    height: 42px;
    line-height: 42px;
    font-size: 28px;
    .icon {
      font-size: 28px;
    }
  }
}

@media (min-width: 1400px) {
  .fiche_produit .add_cart {
    width: 42px;
    height: 42px;
    line-height: 42px;
    font-size: 28px;
    .icon {
      font-size: 28px;
    }
  }
}
