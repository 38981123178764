@mixin cover_img{
  display: block;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
}

@mixin flex_center{
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin absolute_container{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* chemin img */
$base-img-url: "../img/theme";