/* Menu produit */

.mp-menu {

  background-color:$color4;
  min-height: 52px;

  ul {
    list-style: none;
    text-align: left;
    margin-bottom: auto;
    font-family: 'Roboto Condensed', sans-serif;
    padding: 0;

    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    flex-wrap: wrap;
  }
  ul li:before {
    position: absolute;
    left: 10px;
    z-index: -1;
    color: rgba(0,0,0,0.8);
    line-height: 30px;
  }
  ul li {
    display: block;
    position:relative;
    vertical-align: top;
  }
  ul li > a {
    display: block;
    margin: 10px auto;
    outline: none;
    font-size: 11px;
    line-height: 1;
    font-weight: normal;
    color: #fff;
    text-transform : uppercase;
    text-align: center;
    width:60px;
    padding:0 2px;

    -webkit-transition: background 0.3s, box-shadow 0.3s;
    -moz-transition: background 0.3s, box-shadow 0.3s;
    transition: background 0.3s, box-shadow 0.3s;
  }
  ul li:hover > a {
    opacity:0.8;
  }
  ul li > a .icon {
    font-size : 32px;
    display : block;
    margin: auto auto 5px auto;
    line-height : 1;
    vertical-align : middle;
    text-align: center;
    min-height: 35px;
  }
  ul li > ul.smenu {
    display: none;
  }
}


.c-promotions,
.c-producteur {
  background-color:$color3;
}
.c-producteur {
  background-image: url('../img/theme/producteur-icon.png');
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center center;
  a {
    margin-right: 0 !important;
    width: 180px !important;
  }
}


/* sous menu */

.mp-menu ul li:hover > ul.smenu {
  display: block;
  z-index: 2;
  position: absolute;
  top: 78px;
  left: -130px;
  width: 330px;
  background-color: #427487;
  padding: 0.5rem;
}
.mp-menu ul li#mp_0:hover > ul.smenu {
  left: -35px;
}
.mp-menu ul li.end_col + li:hover > ul.smenu {
  left: 0;
}
.mp-menu ul li.end_col:hover > ul.smenu {
  left:auto;
  right:0;
}

.mp-menu ul li:hover > ul.smenu {
  a{
    padding: 0 1em;
    min-width:80px;
    text-align: left;
  }
  .image_cate{
    margin-top: auto;
    margin-bottom: auto;
  }
  .image_cate img{
    max-width: 130px;
  }

}

#header:not(.sticky) .mp-menu ul.smenu li {
  width: auto;
}

.mp-menu ul.smenu {

  > li {
    border-bottom: solid 1px rgba(255,255,255,0.1);
  }

  > li:last-child {
    border-bottom: none;
  }

  a.title_smenu {
    font-size: 16px;
    color: #fff;
    font-weight: normal;
    margin-bottom: 1.5rem;
    width: auto;
    text-transform: none;
  }
  a.title_smenu:hover {
    opacity: 0.8;
  }
  ul.ssmenu {
    padding-left: 0;
  }
  ul.ssmenu li{
    display: block;
  }
  ul.ssmenu a.ssmenu_titre{
    font-size: 14px;
    text-transform: initial;
    font-weight: 400;
  }
  ul.ssmenu .ssmenu_titre:hover {
    color: #465707;
  }
}


/* RESPONSIVE */

@media screen and (min-width: 1400px) {
  .mp-menu ul li > a {
    font-size: 14px;
    width:90px;
  }
  /* Sous menu */
  .mp-menu ul li:hover > ul.smenu { top: 89px; }

}

@media (max-width: $breakpoint-xl) {
  /* Sous menu */
  .mp-menu ul li:hover > ul.smenu { top: 82px; }
}

@media (max-width: $breakpoint-md) {

  /* Menu */
  .mp-menu ul {
    list-style: none;
    text-align : left;
    overflow : hidden;
    -ms-flex-direction : row;
    flex-direction: column;

    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .mp-menu ul li {
    width: auto;
  }
  #menu-desktop.mp-menu ul li > a {
    margin: 0.8em 1em 0.8em 1em;
    font-size: 16px;
    width: auto;
    text-align: left;
  }

  .mp-menu ul li > a .icon {
    font-size : 30px;
    display: inline-block;
    text-align: left;
    margin-bottom: auto;
    margin-right: 5px;
    width: 32px;
  }

  .mp-menu > .mp-level > ul {
    margin: 0;
    padding: 0;
    overflow : auto;
    width : 300px;
    height : 100%;
    position : relative;
  }

  .mp-menu ul li:before {
    position: absolute;
    left: 10px;
    z-index: -1;
    color: rgba(0,0,0,0.8);
    line-height: 30px;
  }

  .mp-level a {
    color : rgba(0, 0, 0, 0.7);
  }

  .c-producteur {
    background: none;
    background-color: #83373b;
    .text {
      text-align: center !important;
    }
  }

  /* Sous menu */
  .mp-menu ul li > ul.smenu {
    position: relative;
  }
  .mp-menu ul li:hover > ul.smenu,
  .mp-menu ul li#mp_0:hover > ul.smenu,
  .mp-menu ul li.end_col:hover > ul.smenu {
    display: none;
    /*position: relative;
    display: block;
    top: 0;
    left: 0;
    max-height: 300px;*/
  }

  #mp-menu .mp-level-2 a {
    /* color : rgba(255, 255, 255, 0.7); */
    text-transform : none;
    line-height : 20px;
    vertical-align : middle;
  }

  #mp-menu .mp-level-2 .centered-height a {
    line-height : 1;
    display : inline-block;
    float : none;
    vertical-align : middle;
  }

  #mp-menu .mp-level-2 .c_container a, #mp-menu .mp-level-2 .c_container span {
    color : rgba(255, 255, 255, 0.7);
  }

  #mp-menu .mp-level-2 a.mp-back {
    text-transform : uppercase;
  }

  #mp-menu .mp-level-2 ul {
    padding : 5px 0;
  }

  #mp-menu .mp-level-2 li {
    padding : 0 5px;
  }

  .mp-level a .text {
    padding-left : 0px;
    vertical-align : middle;
    -webkit-transition: border-color 0.3s ease-in, padding 0.3s ease-in;
    -moz-transition: border-color 0.2s ease-in, padding 0.2s ease-in;
    transition: border-color 0.2s ease-in, padding 0.2s ease-in;
  }

  .mp-level a:hover .text {
    padding-left : 5px;
  }

  .mp-level a .picto {
    margin : 0 10px 0 0;
    font-size : 22px;
    width : 24px;
    overflow : visible;
  }

  .mp-level > ul > li > a {
    box-shadow: inset 0 1px rgba(0,0,0,0.2);
  }

  .mp-menu.out ul li a:hover {
    background: rgba(0,0,0,0.2);
    box-shadow: inset 0 -1px rgba(0,0,0,0);
  }

  .mp-menu.out .mp-level.mp-level-overlay > ul > li > a,
  .mp-level.mp-level-overlay > ul > li:first-child > a {
    box-shadow: inset 0 -1px rgba(0,0,0,0);
  }

  .mp-level > ul > li:first-child > a:hover,
  .mp-level.mp-level-overlay > ul > li:first-child > a {
    box-shadow: inset 0 -1px rgba(0,0,0,0), inset 0 1px rgba(0,0,0,0);
  }

  #mp-menu .mp-level .picto_menu {
    position : static;
    margin : 0 0 0 6px;
    vertical-align : middle;
    border-color: transparent transparent transparent rgba(0, 0, 0, 0.7);

    -webkit-transition: border-color 0.2s ease-in, margin 0.3s ease-in;
    -moz-transition: border-color 0.2s ease-in, margin 0.3s ease-in;
    transition: border-color 0.2s ease-in, margin 0.3s ease-in;
  }

  #mp-menu .mp-level a:hover .picto_menu {
    border-color : transparent transparent transparent #ffffff;
  }

  /* back buttons */

  .mp-back {
    background: rgba(0,0,0,0.1);
    outline: none;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    display: block;
    font-size: 1.2em;
    padding: 1em 1em 1em 3.4em;
    position: relative;
    box-shadow: inset 0 1px rgba(0,0,0,0.1);
    -webkit-transition: color 0.2s, background 0.3s, padding 0.2s;
    -moz-transition: color 0.2s, background 0.3s, padding 0.2s;
    transition: color 0.2s, background 0.3s, padding 0.2s;
  }

  .mp-back:hover {
    padding : 1em 1em 1em 4em;
  }

  .mp-menu.out .mp-level.mp-level-overlay > .mp-back {
    background: transparent;
    box-shadow: none;
    color: transparent;
  }

  #mp-menu  .mp-back .picto_menu, #mp-menu .mp-level-2 .mp-back .picto_menu {
    position:absolute;
    top:50%;
    margin-top:-2.5px;
    left:18px;
    display:inline-block;
    width:0px;
    height:0px;
    border-width : 3px 4px 3px 0;
    border-color : transparent #ffffff transparent transparent;
    border-style : solid;
    vertical-align:middle;
    /* background:url(../img/sprite.png) no-repeat -10px -20px; */
    overflow:hidden;
  }

  #mp-menu  .mp-back .picto_menu {
    border-color : transparent rgba(0, 0, 0, 0.7) transparent transparent;
  }

  #mp-menu  .mp-level .mp-back:hover .picto_menu {
    border-color : transparent #ffffff transparent transparent;
  }

  .mp-level li{
    margin-left:0;
  }
}

