@font-face {
  font-family: 'Roboto';
  src: local('Roboto Light'), local('Roboto-Light'),
  url('../fonts/subset-Roboto-Light.woff2') format('woff2'),
  url('../fonts/subset-Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap; /* https://developer.mozilla.org/fr/docs/Web/CSS/@font-face/font-display */
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), local('Roboto-Regular'),
  url('../fonts/subset-Roboto-Regular.woff2') format('woff2'),
  url('../fonts/subset-Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap; /* https://developer.mozilla.org/fr/docs/Web/CSS/@font-face/font-display */
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold'), local('Roboto-Bold'),
  url('../fonts/subset-Roboto-Bold.woff2') format('woff2'),
  url('../fonts/subset-Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap; /* https://developer.mozilla.org/fr/docs/Web/CSS/@font-face/font-display */
}
@font-face {
  font-family: 'Roboto Condensed';
  src: local('Roboto Condensed'), local('RobotoCondensed-Regular'),
  url('../fonts/subset-RobotoCondensed-Regular.woff2') format('woff2'),
  url('../fonts/subset-RobotoCondensed-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap; /* https://developer.mozilla.org/fr/docs/Web/CSS/@font-face/font-display */
}

/* letter spacing */
.space50 {
  letter-spacing: 0.1rem;
}