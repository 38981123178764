/* Largeur element general de la page */

#bandeauf, .largeur, .container
{
  width : calc(100% - 60px);
  max-width : 1464px;
  margin-left: auto;
  margin-right: auto;
}
.largeur, .container
{
  margin:auto;
}

#header {
  position: relative;
  z-index:7;
  background-color: #fff;

  .col_center {
    align-self: flex-end;
  }
}

#header > .row{
  height: 100%;
}

/* Menu principal */
#overlay {
  @include absolute_container;
  position: fixed;
  background-color: rgba(0,0,0,0.4);
  transition: all .600ms ease-in;
  opacity:0;
  max-height:0;
  /*z-index: -1;*/
  &.show {
    opacity:1;
    max-height:100%;
  }
}
#primary_nav_wrap {
  /*width: 70rem;
  margin-left: auto;*/
  flex: 1;

  ul {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;

    a {
      color: $color2;
      font-size: 14px;
      font-weight: 500;
      transition: all .2s ease-in;

      &:hover,
      &:focus {
        color: $color2;
      }
      &.m-color1:hover,
      &.m-color1:focus {
        color: $color2;
      }
      &.m-color2:hover,
      &.m-color2:focus {
        color: $color2;
      }
      &.m-color3:hover,
      &.m-color3:focus {
        color: $color2;
      }
      .text-menu-access,
      &:before { vertical-align: middle; }

      &:before,
      &.m-color1:before,
      &.m-color2:before,
      &.m-color3:before {
        content: '';
        display: inline-block;
        margin-right: 5px;
        height: 12px;
        border-left: 4px solid $color2;
      }
      &.m-color1:before {
        border-left-color: $color2;
      }
      &.m-color2:before {
        border-left-color: $color2;
      }
      &.m-color3:before {
        border-left-color: $color2;
      }

    }
  }

  &,
  ul.first_level {
    height: 10rem;
  }
  ul.first_level {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    @media (max-width: $breakpoint-lg) {
      margin-left: 2em;
    }


    & > li {
      display: flex;
      align-items: center;
      height: 100%;
      cursor: pointer;
    }

    /*& > li.dropdown:hover > .second_level,*/
    & > li.dropdown.show > .second_level,
    /*& > li.dropdown > a:hover ~ .second_level,
    & > li.dropdown > a:focus ~ .second_level*/ {
      opacity: 1;
      visibility: visible;
    }
  }
}

#primary_nav_wrap .second_level {
  position: absolute;
  z-index: 2;
  top: 100%;
  right: 0;
  left: 0;
  background-color: $color2;
  color: $color1;
  cursor: initial;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s, visibility .3s;
  a {
    color: $color1;
  }

  .title_smenu {
    font-size: 4.5rem;
    font-weight: 400;
    color: $color1;
    height: 16.4rem;
    text-align: center;
    padding-top: 4rem;
    &::after {
      content:'';
      position: absolute;
      top: 2.6rem;
      right: 0;
      display: inline-block;
      width: .1rem;
      height: 11.2rem;
      background-color: $color1;
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5rem;
  }
  .s_menu {
    > a {
      font-size:25px;
      &:before,
      &.m-color1:before,
      &.m-color2:before,
      &.m-color3:before {
        height: 16px;
      }
    }
    &.catalogue {
      background-color: rgba(255,255,255,0.8);
      color: $color2;
      a { color: $color2; }
    }
  }
}

/* Sticky */
#header .header_top.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $color2;
  z-index: 5;
  border-bottom: none;
  box-shadow: 0 .2rem 2rem 0 rgba(0, 0, 0, .3);
  .header_content .line1 { display:none !important; }
  a {
    /*color: $color2;*/
    &:hover,
    &:focus{
      /*color: $color6;*/
    }
  }
  #primary_nav_wrap ul.first_level {
    height: 100%;
    .second_level {
      top: 10rem;
      border-top: .1rem solid #fff;
    }
  }
}

@media (max-width: $breakpoint-md) {

  #primary_nav_wrap {
    width: auto;

    ul.first_level {
      display: none;
    }
  }

}

@media (max-width: $breakpoint-sm) {
  .sticky #primary_nav_wrap {
    &,
    ul.first_level {
      height: 5rem;
    }
  }
}



/* ACCES */
#acces {
  ul {
    list-style: none;
    padding: 0;
  }
  #accesmenu {
    margin: auto 0 auto auto;
  }
  ul.first_level {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;

    & > li {
      display: flex;
      align-items: center;
      height: 100%;
      cursor: pointer;
      position: relative;
      a {
        color: $color2;
        font-size: 14px;
        font-weight: 500;
        transition: all .2s ease-in;
        @media (max-width: $breakpoint-sm) {
          font-size: 12px;
        }

        > .icon,
        > .text {
          vertical-align: baseline;
        }


        &:hover,
        &:focus {
          color: $color2;
        }
      }
      &.dropdown .icon {
        font-size:9px;
      }
    }

    & > li.dropdown:hover > .second_level,
    & > li.dropdown > a:hover ~ .second_level,
    & > li.dropdown > a:focus ~ .second_level {
      opacity: 1;
      visibility: visible;
    }
    & > li.dropdown:hover > a > .icon {
      opacity: 1;
      visibility: visible;
    }
  }
  .second_level {
    position: absolute;
    z-index: 2;
    top: calc(100% - 10px);
    left: 0;
    width:200px;
    background-color: $color2;
    color: $color2;
    cursor: initial;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s, visibility .3s;
    > ul {
      padding: 10px 15px;
    }
  }
}




@media (max-width: $breakpoint-sm) {
  #bandeauf, .largeur, .container
  {
    width : 100%;
  }
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-right: 10px;
    padding-left: 10px;
  }
}
@media (min-width: $breakpoint-xl) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: $breakpoint-xl;
  }
}

