/*RECHERCHE HEADER *****/

.searchblock {

  position: relative;

  .searchbar {
    position: relative;
    right: auto;
    top: auto;
    z-index: 5;
    overflow: hidden;
    width: auto;
    transition: all 0.5s ease-in-out;
    form {
      width: auto;
    }
  }

  > a {
    display: none;
  }

  #search
  {
    width: 320px;
    margin: 0;
    padding: 5px 8px 5px 3px;
    background: none;
    border: none;
    display: inline-block;
    float: left;
    color: $color2;
    border-radius: 0;
    border:1px solid $color2;
    background: none;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    transition: all 0.3s ease-in-out;
    font-size: 1.6rem;
    padding-left: 1rem;
    color: #709AAB;
    font-weight: bold;
  }
  #search:focus
  {
    background: #e7e7e7;
    box-shadow:none;
  }

}

.searchblock > a {
  display:none;
}

.searchbar
.searchbar,
.searchbar form,
#search,
#btsearch {
  height: 40px;
}

#btsearch:focus {
  outline:0 !important;
}

#scrollrecherche
{
  position:absolute;
  z-index:3;
  top:15px;
  right:-11px;
  width:231px;
  vertical-align : top;
}

#boiterecherche
{
  padding:0;
  width:230px;
  text-align:left;
  position:absolute;
  color: #465707;
  border:1px solid #c5c5c5;

  -moz-box-shadow: 0px 0px 6px #888;
  -webkit-box-shadow:  0px 0px 6px #888;
  box-shadow:  0px 0px 6px #888;
  background-color:#444; /* bug ie 7 */
}
#boiterecherche.fixed { position:fixed; }
#boiterecherche.absolute { position:absolute; }

#boiterecherche .boite3f
{
  padding: 1px 7px 7px 10px;
}
#boiterecherche legend {
  display: none;
}
.bt,
#search
{
  vertical-align:middle;
}


/* Bouton formulaire recherche */
#search::-webkit-input-placeholder { color:#709AAB; }
#search::-moz-placeholder { color:#709AAB; }
#search::-ms-input-placeholder { color:#709AAB; }

#btsearch {
  position:relative;
  border: 0;
  color: $color2;
  width: 50px;
  display: inline-block;
  font-size: 35px;
  line-height: 40px;
  cursor: pointer;
  vertical-align: top;
  margin-left: 5px;
  transition: all 0.3s ease-in-out;
  background: none;
}
#search + #btsearch:before {
  content:'';
  position:absolute;
  left:0;
  bottom:0;
  width:0;
  transition: all 0.3s ease-in-out;
}
#search:focus + #btsearch:before {
  width:100%;
  transition: all 0.2s ease-in-out;
}

#btsearch .icon{
}

#boiterecherche .rechmarquecontent
{
  display:block;
  margin-top:-5px;
}

#boiterecherche .rechmarque
{
  width:155px;
}

/**** RESPONSIVE ****/

@media (max-width: $breakpoint-xl) {
  .searchblock #search {
    width: 300px;
  }
}


@media (max-width: $breakpoint-lg) {
  .searchblock #search {
    width: 201px;
  }
}

@media (max-width: $breakpoint-md) {
  .col-search {
    padding: 0;
  }
  .searchblock #search {
    width: 180px;
  }
}

@media (max-width: $breakpoint-sm) {

  .searchblock {
    position:static;
  }

  .searchbar {
    position:absolute;
    left:5px;
    top: 3px;
    width: 0;
  }

  #search {
    font-size: 11px !important;
  }

  #search:focus, #search:focus + #btsearch {
    background: rgba(255,255,255,0.4);
    box-shadow: none;
  }

  #btsearch {
    line-height: 40px;
  }
  .searchblock #search {
    width: calc(100% - 60px);
    height: 35px;
  }
  #header .col-right .block_link .icon-loupe {
    font-size: 25px;
    padding-bottom: 8px;
  }
}
@media (max-width: $breakpoint-xs) {
  > a {
    display: block;
    position: relative;
    left: 10px;
  }
  .searchblock .searchbar {
    position: absolute;
    left: 5px;
    top: 3px;
    background-color: #fff;
    width: 0;
  }
  .searchblock > a {
    display: block;
    position: relative;
    left: 10px;
  }
  #header .col-right .block_link .icon-loupe {
    font-size: 22px;
  }
  .searchbar.show {
    width: 275% !important;
    padding-top: 4px;
  }

}
