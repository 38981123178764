.mobile-only {
  display: none;
}

/* header mobile menu */
#menu-desktop header.mobile-only {
  background: #fff;
  height: 70px;
}


#bandeau {
  position:relative;
  margin:auto;
  z-index:6;
  height: 0;
  text-align: left;
}

#bandeau, #contenu
{ /* anul le bug positionnement ie 5 */
  text-align:left;
}

#menu_launcher {
  display : none;
  cursor : pointer;
}

#menu_launcher .text{
  font-weight: 100;
  margin-right: 50px;
  line-height: 40px;
}

/* icon menu */
.m_icon::before {
  background: #fff none repeat scroll 0 0;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  position: absolute;
  top: -8px;
  width: 24px;

  transition : all 0.2s ease-in-out;
  -ms-ransition : all 0.2s ease-in-out;
  -webkit-transition : all 0.2s ease-in-out;
}
.m_icon::after {
  background: #fff none repeat scroll 0 0;
  bottom: -8px;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  position: absolute;
  width: 24px;

  transition : all 0.2s ease-in-out;
  -ms-ransition : all 0.2s ease-in-out;
  -webkit-transition : all 0.2s ease-in-out;
}
.m_icon {
  background: #fff none repeat scroll 0 0;
  display: inline-block;
  height: 2px;
  margin: 6px 0 10px 0;
  position: relative;
  vertical-align: middle;
  width: 24px;

  transition : all 0.2s ease-in-out;
  -ms-ransition : all 0.2s ease-in-out;
  -webkit-transition : all 0.2s ease-in-out;
}

/* close state */

.mp-pushed .m_icon{
  background:none;
}
.mp-pushed .m_icon:before{
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  top: 0;
}
.mp-pushed .m_icon:after{
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  bottom: 0;
}


/* menu mobile */

@media (min-width: $breakpoint-md) {
  #home #menu-desktop {
    display: none;
  }

}



@media (max-width: $breakpoint-md) {

  #menu-desktop {
    width: 300px;
    overflow-y: auto;
  }

  #contenu {
    margin-left: 0;
    margin-top: 40px;
  }

  #bandeau {
    max-width: inherit;
    min-width: inherit;
    width: auto;
  }

  #menu_launcher {
    position : absolute;
    display : block;
    top : 85px;
    left : 0;
    right: 0;
    padding: 0 20px;
    background-color: $color2;
    color: #fff;
    text-transform: uppercase;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
  }



  /* Fallback example for browsers that don't support 3D transforms (and no JS fallback) */
  /* We'll show the first level only */
  .no-csstransforms3d .mp-pusher,
  .no-js .mp-pusher {
    padding-left: 0;
  }

  .no-csstransforms3d .mp-menu .mp-level,
  .no-js .mp-menu .mp-level {
    display: none;
  }

  .no-csstransforms3d .mp-menu > .mp-level,
  .no-js .mp-menu > .mp-level {
    display: block;
  }

  body.menuhidden {
    overflow : hidden;
  }

  .mp-menu {
    position: absolute; /* we can't use fixed here :( */
    top: 0;
    left: 0;
    z-index: 1;
    width: 300px;
    height: 100vh;
    background : $color2;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);

    transition : transform 0.5s;
    -webkit-transition : transform 0.5s;
    -ms-transition : transform 0.5s;
  }

  /* styles to different menu dropdown */

  .mp-menu .fooding li:hover{
    background-color:rgba(150, 213, 60, 0.8);
  }

  .mp-menu .eboutique li:hover{
    background-color:rgba(203, 11, 117, 0.8);
  }

  /* -------- */

  .mp-level>ul>li>a>span{
    color:#333333;
  }

  /* menu mobile */

  .mp-level {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: auto;
    height : calc( 100vh - 50px );
    overflow : hidden;
    background: #F4F4F4;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  .mp-menu > .mp-level {
    top : 115px;
  }

  .mp-menu > .mp-level.mp-level-open {
    height: calc( 100% - 50px );
  }

  .mp-level.mp-level-open {
    height : calc( 100vh - 50px );
    overflow : auto;
  }

  .mp-level.mp-level-open.mp-level-overlay {
    overflow : hidden;
    height : 200vh;
  }

  .mp-level.mp-level-open.mp-level-overlay > ul > li:not(.m_current) {
    opacity : 0;
    visibility : hidden;
  }

  .mp-level.mp-level-overlay {
    cursor: pointer;
  }

  .mp-level.mp-level-overlay.mp-level:before {
    width: 100%;
    height: 100%;
    background: transparent;
    opacity: 1;
  }

  .mp-pusher,
  .mp-level {
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    transition: transform 0.5s;
  }

  /* overlap */
  .mp-overlap .mp-level.mp-level-open {
    box-shadow: 1px 0 2px rgba(0,0,0,0.2);
    -webkit-transform: translateX(-40px);
    -ms-transform: translateX(-40px);
    transform: translateX(-40px);

  }

  /* First level */
  .mp-menu > .mp-level,
  .mp-menu > .mp-level.mp-level-open,
  .mp-menu.mp-overlap > .mp-level,
  .mp-menu.mp-overlap > .mp-level.mp-level-open {
    box-shadow: none;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: transform 0.5s, -webkit-transform 0.5s;
    -moz-transition: transform 0.5s, -moz-transform 0.5s;
    transition: transform 0.5s;
  }

  /* cover */
  .mp-cover .mp-level.mp-level-open {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  .mp-cover .mp-level.mp-level-open > ul > li > .mp-level:not(.mp-level-open) {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  /* titles */

  .mp-menu .title {
    margin: 0 20px;
    color : rgba(0, 0, 0, 0.7);
    text-shadow: 0 0 1px rgba(0,0,0,0.1);
    font-size: 16px;
    min-height : 40px;
    line-height : 40px;
    padding : 0.7em 0;
    text-transform: uppercase;
    font-weight: 400;
    display : block;
  }

  .mp-menu .title .text {
    vertical-align : middle;
    margin-left : 20px;
  }

  .mp-menu .title .picto + .text {
    margin-left : 0;
  }

  .mp-menu .mp-level .mp-level .mp-level .title, .mp-menu .close_bloc .title {
    color: #ffffff;
  }

  .mp-menu .mp-level .mp-level .mp-level .title a {
    vertical-align : middle;
    line-height : 18px;
    display : inline-block;
    padding-bottom: 3px;
  }

  .mp-menu .title a:hover, .mp-menu ul li .title a:hover {
    background : transparent;
    color : #F04A31;
  }

  .mp-menu .title .picto, .mp-menu .title a .picto {
    font-size : 40px;
    line-height : 40px;
    overflow : visible;
    width : auto;
    margin: 0 5px;
  }

  .mp-menu .title .picto + .centered-height {
    width : calc(100% - 60px);
  }

  .mp-menu.mp-overlap .title:before {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 8px;
    font-size: 75%;
    line-height: 1.8;
    opacity: 0;
    -webkit-transition: opacity 0.3s, -webkit-transform 0.1s 0.3s;
    -moz-transition: opacity 0.3s, -moz-transform 0.1s 0.3s;
    transition: opacity 0.3s, transform 0.1s 0.3s;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  .mp-overlap .mp-level.mp-level-overlay > .title:before {
    opacity: 1;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    -moz-transition: -moz-transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }


  /* fancy mobile rules */

  .mp-pusher {
    transition : transform 0.5s;
    -webkit-transition : transform 0.5s;
  }

  .fancy_visible .scroller, .menu_visible .scroller {
    overflow: visible; /* bug sur ipad home dragée passé de hidden a visible */
    position: relative;
    height: 100%;
  }

  .mp-fancy {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(-340px);
    -webkit-transform: translateX(-340px);
    -ms-transform: translateX(-340px);
    width: 340px;
    z-index: 1;
  }

  .scroller:before{
    position: absolute;
    top: 0;
    right: 0;
    width : 0;
    height : 0;
    content: '';
    display : block;
    z-index : 9999;
  }

  .scroller.mp-pushed {
    cursor:pointer;
  }

  .scroller.mp-pushed:before{
    left : 0;
    bottom : 0;
    width : auto;
    height : auto;
    background : rgba(0, 0, 0, 0.5);

    transition : background 0.5s;
    -webkit-transition : background 0.5s;
  }

  .mp-pusher.fancy_visible {
    transform: translateX(340px);
    -webkit-transform: translateX(340px);
    -ms-transform: translateX(340px);
  }

  .mp-pusher.menu_visible {
    transform: translateX(300px);
    -webkit-transform: translateX(300px);
    -ms-transform: translateX(300px);
  }

  .mp-pusher::after{
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    content: '';
    opacity: 0;
  }

  .mp-pusher.fancy_visible {
    transform: translateX(340px);
    -webkit-transform: translateX(340px);
    -ms-transform: translateX(340px);
  }

  #mp-fancy > .header {
    border-bottom : 5px solid #616e8a;
    position:relative;
  }

  #menu_launcher a {
    display : block;
  }
  .mobile-only {
    display: block;
  }
  .mobile-only img.logo_nosticky {
    width: 300px;
  }
  #header .mp-menu ul li,
  body #header:not(.sticky) .mp-menu ul li {
    width: auto;
  }
  #menu-desktop header.mobile-only {
    width: 100%;
    padding-top: 1.5rem;
  }
}

@media (max-width: $breakpoint-sm) {
  #menu_launcher {
    top: 50px;
  }
}

@media (max-width: $breakpoint-xs) {

  #menu-desktop header.mobile-only {
    width: 100%;
    padding-top: 1.5rem;
  }

  #header .mp-menu ul li,
  body #header:not(.sticky) .mp-menu ul li {
    width: auto;
  }

  #menu_launcher {
    top: 50px;
  }

}