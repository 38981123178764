/* Variables couleurs */

$color1: #fff;
$color2: #00506F;
$color3: #83373B;
$color4: #4F869B;
$color5: #F4F4F4;
$color6 : #E6AB8F;




.color2 {color:#00506F;}
.color4 {color:#4F869B;}

.bg-color1 {
  background-color: $color2;
}

.bg-color2 {
  background-color: $color5;
}

.color-red {
  color: #df3737;
}
