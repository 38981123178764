//////////////////////////////////////
// Boutons
//////////////////////////////////////

$btn-primary-bgcolor: $color3;
$btn-primary-bgcolorhover: $color4;
$btn-primary-bordercolor: #999999;
$btn-primary-bordercolorhover: #999999;

$btn-secondary-bgcolor: $color4;
$btn-secondary-bgcolorhover: #999999;
$btn-secondary-bordercolor: #999999;
$btn-secondary-bordercolorhover: #999999;

$btn-primary-bgdisable: #999999;
$btn-primary-bordercolordisable: #999999;

$btn-outline-primary-bgcolor: #fff;
$btn-outline-primary-bgcolorhover: $color3;
$btn-outline-primary-bordercolor: $color3;
$btn-outline-primary-bordercolorhover: $color3;

body:not([class*="breakpoint"]) {

  .btn.focus, .btn:focus,
  .btn:not(:disabled):not(.disabled).active:focus,
  .btn:not(:disabled):not(.disabled):active:focus,
  .show > .btn.dropdown-toggle:focus {
    box-shadow: none;
  }

  /* btn primary */
  .btn-primary {
    position: relative;
    background: $btn-primary-bgcolor !important;
    color: white !important;
    border: none;
    border-color: $btn-primary-bordercolor;
    border-radius: 5rem;
    font-size: 2rem;
    padding: 1rem 2.2rem 1rem 2.2rem;
    font-weight: bold;
    z-index: 1;

    &:hover,
    &:focus {
      background: $btn-primary-bgcolorhover;
      border-color: $btn-primary-bordercolorhover;
    }
  }

  #header_home_content .btn-primary:after {
    position: absolute;
    bottom: 6px;
    left: -8px;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    border: solid 1px #000;
    border-radius: 5rem;
    z-index: 0;
  }

  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active {
    background: $btn-primary-bgcolor;
    border-color: $btn-primary-bgcolor;
  }

  .btn-primary.disabled, .btn-primary:disabled {
    background: $btn-primary-bgdisable;
    border-color: $btn-primary-bordercolordisable;
  }

  /* btn secondary */
  .btn-secondary {
    position: relative;
    background: $btn-secondary-bgcolor !important;
    color: white !important;
    border: none;
    border-color: $btn-secondary-bgcolor;
    border-radius: 5rem;
    font-size: 2rem;
    padding: 1rem 2.2rem;
    font-weight: bold;
    z-index: 1;
    &:hover {
      opacity: 0.8;
    }
  }

  /* btn outline */
  .btn-outline-primary {
    border: solid 1px;
    border-color: $btn-outline-primary-bordercolor;
    color: $btn-outline-primary-bgcolor;
    background: #fff;
    border-radius: 5rem;
    font-size: 2rem;
    padding: 1rem 2.2rem;
    font-weight: bold;
    color: $color3;

    &:hover {
      background-color: $btn-outline-primary-bgcolorhover;
      border-color: $btn-outline-primary-bordercolorhover;
      color: white;
    }
  }

  /* Button cms */
  #body_cms .content .button,
  .button {
    position: relative;
    background: $btn-primary-bgcolor !important;
    color: white !important;
    border: none;
    border-color: $btn-primary-bordercolor;
    border-radius: 5rem;
    font-size: 2rem;
    padding: 1rem 2.2rem 1.5rem 2.2rem;
    font-weight: bold;
    z-index: 1;
    transition: all .1s ease-in;
    display: inline-block;

    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }

  .button.button-vert {
    background-color: #6cc332;
    color: #fff;
    border: 1px solid #6cc332
  }

  .button.button-rouge {
    background-color: #dd3b59;
    color: #fff;
    border: 1px solid #dd3b59
  }

  .button.button-orange {
    background-color: #f88e13;
    color: #ffe7cb;
    border: 1px solid #ffb560
  }

  .button.button-violet {
    background-color: #92278f;
    color: #fff;
    border: 1px solid #92278f
  }

  .button.button-blanc {
    background-color: #fff;
    color: #3d3d3d;
    border: 1px solid #3d3d3d
  }

  .button.button-bleu-clair {
    background-color: #26afb8;
    color: #fff;
    border: 1px solid #26afb8;
    padding: 5px 40px;
    margin-top: 2rem
  }

  .button.button-vert:focus,
  .button.button-vert:hover {
    background-color: rgba(255, 255, 255, .8);
    color: #6cc332;
    border: 1px solid #6cc332
  }

  .button.button-rouge:focus,
  .button.button-rouge:hover {
    background-color: rgba(255, 255, 255, .8);
    color: #dd3b59;
    border: 1px solid #dd3b59
  }

  .button.button-violet:focus,
  .button.button-violet:hover {
    background-color: #fff;
    color: #92278f;
    border: 1px solid #92278f
  }

  .button.button-blanc:focus,
  .button.button-blanc:hover {
    background-color: #3d3d3d;
    color: #fff;
    border: 1px solid #3d3d3d
  }

  .button.button-bleu-clair:focus,
  .button.button-bleu-clair:hover {
    background-color: rgba(255, 255, 255, .8);
    color: #26afb8;
    border: 1px solid #26afb8
  }

  .btn-large {
    padding: 1.8rem 3rem;
    font-size: 2.4rem;
  }

  .btn-small {
    padding: 1rem 1.5rem !important;
    font-size: 1.4rem !important;
  }

  .button.btn-big {
    font-size: 14px;
    padding: 15px 25px
  }

  .button.btn-block {
    display: block;
    width: 100%
  }

  .button.btn-inline {
    display: inline-block
  }

  #bt_panier_buy {
    border-radius: 0;
  }

  /* Bouton rupture page article */
  .button.acheter {
    background:  #34cba2 !important;
  }
  .button.rupture,
  .button.rupture:hover {
    -webkit-transition: all 0;
    -moz-transition: all 0;
    -ms-transition: all 0;
    -o-transition: all 0;
    transition: all 0;
    cursor: default !important;
    background-color: #fff !important;
    border: 1px solid #c3c3c3 !important;
    color: #999 !important;
  }
}