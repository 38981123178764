footer {
  background: url("../img/theme/bg-riz.png") no-repeat -300px center;
  background-color: $color4;
  margin-left: -15px;
  margin-right: -15px;
}
#footer_content {
  position: relative;
  color: #fff;

  .icon {
    color: #fff;
    font-size: 6rem;
  }
  .icon-mail {
    font-size: 5rem;
  }
  p {
    color: #fff;
  }
  a:focus,
  a:hover{
    color: #fff;
  }
}

#mentions {
  border-top: solid 1px rgba(255, 255, 255, 0.3);
  margin-top: 4rem;
  padding-top:2rem;
  padding-bottom: 2rem;
  font-size: 1.4rem;
  ul, li {
    margin: 0;
    padding: 0;
  }
  li {
    display: inline-block;
  }
  a {
    color: #fff;
  }
}


/* Signature Kyxar */
.kyxar {
  position: absolute;
  z-index: 9;
  white-space: nowrap;
  right: 3rem;
  margin-top: -3rem;
  padding: 0;
  line-height: 2.5rem;
}

.kyxar a {
  color: #fff;
  font-size: 1rem;
  text-decoration: none;
  letter-spacing: 1px;
  position: relative
}

.kyxar a:hover {
  color: $color1;
}

.kyxar a .none {
  position: absolute;
  bottom: 3.8rem;
  right: 2.8rem;
  height: 0;
  overflow: hidden;
  color: $color3;
}

.kyxar a:hover .none {
  overflow: visible
}

.kyxar a .none span {
  display: inline-block;
  background-color: #fff;
  border-radius: .3rem;
  padding: .5rem 1rem;
  line-height: 1;
  font-weight: 300;
  font-size: 1rem
}

@media (max-width: $breakpoint-md) {
  footer {
    background: none;
    background-color: $color4;
  }
}

@media (max-width: $breakpoint-sm) {

}