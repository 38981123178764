/* ----------------------------------------------------------------- */
/* Fiche
/* ----------------------------------------------------------------- */

.grey_sep {
  width: 100%;
  height: 1px;
  background-color: #ddd;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

#fiche .bloc_description {
  .h1, .h2 {
  text-align: left;
  margin: 0 0 1rem 0;
  font-weight: bold;
  }
  .h1 {
    margin-bottom: 0;
    font-size: 32px;
  }
  .h2,
  .h2 a {
    color:$color6;
  }
  .h2 {
    margin-bottom: 0;
    font-size: 18px;
    margin-top:5px;
  }
}

#blocfiche
{
  position : relative;
  float : left;
  margin-right : 40px;
  margin-bottom : 10px;
}

.prod_right_col {
  float : left;
}

.blocimg_content /* 2848 4288 */
{
  position:   relative;
  text-align: center;
  overflow:   hidden;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
#fiche #blocimg /* 2848 4288 */
{
  position:   relative;
  border:     1px solid #C4CCBF;
  width: 100%;
}
#fiche #blocimg #lst_zoom li {
  list-style-type: none;
  margin-left: auto;
}


/* centrage vertical de l'image */
.blocimg_content,
#lst_zoom li,
#lst_zoom figure {
  height:100%;
}

#zoom_image {
  display : inline-block;
  vertical-align : middle;
  max-height : 100%;
}
#lst_zoom {
  padding: 0;
  margin: auto;

  figure{
    margin: 0;
  }
  .icon-loupe {
    font-size: 3.4rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: .0;
    color:#fff;
    text-shadow: 0 0 5px rgba(0,0,0,0.6);
  }
  a:hover .icon-loupe,
  a:focus .icon-loupe {
    font-size: 9.4rem;
    opacity: .6;
  }
  .zoom_image img.tnimgprd {
    width: 100%;
    height: 100%;
    max-height:490px;
    object-fit: contain;

  }
}


#lst_vignette{
  margin: .5rem -.2rem 1rem;
  display: flex;
  list-style: none;
}

#lst_vignette a{
  display : inline-block;
  margin : 0 .2rem;
}

#lst_vignette a img{
  width : 6.3rem;
  height: 6.3rem;
  object-fit: cover;
}



#fiche #bloc_add_quantity
{
  overflow:   visible;
  text-align: justify;

  display: -ms-flexbox;
  display: flex;

  -ms-flex-pack: center;
  align-content: center;

  -ms-flex-align: center;
  align-items: center;

  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;

  -ms-flex-direction : row;
  flex-direction : row;

  width:100%;
}

/* marque */
.fichemarque
{
  margin-top: 0em;
  margin-bottom: 0.5em;
  margin-right: 0;
  text-align: right;
}

#fiche .fichemarque
{
  float:right;
  margin-left: 1em;
  text-align: right;
}

.fichemarque {
  img
  {
    padding-right:5px;
    vertical-align:middle;
  }
  a
  {
    font-size:  100%;
    color:      #666;
    font-size:  95%;
  }
  a strong
  {
    font-weight:normal;
    text-decoration:underline;
  }
  a:hover strong
  {
    text-decoration:none;
  }
}


#fiche .fichemarque a
{
  font-size:  80%;
}


#fiche #bloc_prix
{
  text-align:right;
  a {
    background : #853761;
    color : #ffffff;
    font-size : 11px;
    padding : 4px 6px;
    display : inline-block;
    margin-top : 5px;
    border-radius : 3px;
  }

}


#full_descript {
  margin : 10px 20px;
}
.fancybox-container #full_descript {
  width:40%;
}

/* Messages rupture */
#livraison_msg {

  #msgDispo {
    color: #98be00;
  }
  #msgRupture {
    color : #e34f2b;
  }
  span {
    float : left;
  }
  img
  {
    padding-right:2px;
    vertical-align:middle;
  }
  .linetextrupture {
    opacity: .7;
  }

}


/* Tabs sur page article */

#tabs_article{
    margin-top: 2rem;
  }
.nav-tabs {
    border-bottom: 1px solid #DEDEDB;

    -webkit-box-pack : justify;
    -ms-flex-pack : justify;
    justify-content: space-between;

    .nav-item {
      margin-bottom: -2px;
    }
    .nav-link{
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 100;
      color: #787676;
      padding: .1rem 0.1rem;
      margin: auto;
    }
    .nav-link:focus, .nav-link:hover {
      border-color: transparent;
    }
    .nav-item.show .nav-link, .nav-link {
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 4px solid transparent;
    }
    .nav-item.show .nav-link, .nav-link.active {
      background-color: transparent;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 3px solid $color3;
    }
}

#tabs_article {

    .tab-content {
      min-height: 100px;
    }
    .tab-content > div{
      padding: 2rem 0;
      line-height: 1.4;
      margin-bottom: 0;
      font-size:16px;
      font-weight: 400;
      color:#828282;
      text-align: justify;
    }
    .tab-content > div > p {
      margin-bottom: 0;
    }
}


.tab_description p { margin-bottom: 0.5rem; }

#description_tab img{
    max-width: 120px;
    max-height: 120px;
    margin: .5rem;
  }

#btpanier .textaddcart,
#btpanier .textnondispo,
#btpanier .textrupture,
.linetextrupture {
  display : none;
}

#btpanier.dispo .textaddcart {
  display : inline-block;
}

#btpanier.indispo .textnondispo {
  display : inline-block;
}
#btpanier.rupture .textrupture {
  display : inline-block;
}
.linetextrupture.rupture {
  display: block;
}

select#inpQty {
  border-radius: 5px;
}

/* Prix */
.prix
{
  display:block;
  color:$color2;
  font-size:32px;
  margin-bottom:auto;
}
.prix_kg {
  font-size: 14px;
  color: #787676;
  display: block;
  line-height: 1.6;
}
.fiche_produit .produit_infos .prix_barre {
  text-decoration: line-through;
  font-size: 1.6rem;
}
.prix_barre {
    text-decoration: line-through;
    font-size: 1.6rem;
}

/* Button no member */

#bloc_add_quantity .button-no-member .icon {
  font-size: 34px;
  margin-left: 10px;
}

/* Bloc du meme */
#meme_producteur {
  h2 {
    color: $color3;
  }
}

/* Labels */
.sticker_content {
  position: absolute;
  top: 10px;
  left: 10px;
}
.sticker_new {
  background-color: #97C00C;
  color: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  height: 50px;
  line-height: 50px;
  width: 50px;

  display: -ms-flexbox;
  display: flex;

  -ms-flex-direction: column;
  flex-direction: column;

}
.sticker_content > div {
  margin-bottom: 8px;
}
.tag_new,
.tag_degressif {
  text-transform: uppercase;
  font-size: 12px;
  vertical-align: middle;
}
.tag_degressif {
  font-size: 11px;
}
.sticker_degressif {
  background-color: #e95101;
  color: #fff;
  border-radius: 5px;
  padding: 2px 10px 2px 10px;
  height: auto;
  width: auto;
  line-height: 11px;
}

.sticker_promo {
  background-color: #e95101;
  color: #fff;
  border-radius: 5px;
  padding: 3px 10px 3px 10px;
  height: auto;
  width: auto;
  position: absolute;
  bottom: -5px;
  left: 0px;
  line-height: 1;
  font-size: 1.2rem;
}

.sticker_label {
  display: -ms-flexbox;
  display: flex;

  -ms-flex-direction: column;
  flex-direction: column;

  position: absolute;
  top: 10px;
  right: 5px;
  text-align: right;
}
.sticker_label .tag {
  margin-bottom:10px;
  overflow:hidden;
}
.sticker_label .tag > img {
  overflow:hidden;
  max-height:50px !important;
}
.page_catalogue .sticker_label .tag > img,
.fiche_produit .sticker_label .tag > img {
  max-height:30px !important;
}
.sticker_label.sticker_label_marque {
  position: relative;
  text-align: center;
  top: auto;
  right:auto;
}

/* FIN labels */
