/****** FORMULAIRE ******/
* {
  outline: -webkit-focus-ring-color auto 0px;
  outline: 0px auto -webkit-focus-ring-color;
  outline: none;
}

input, button {
  font-family: 'Roboto', sans-serif;
  outline: -webkit-focus-ring-color auto 0px;
  outline: none;
}

.formline,
.submitline {
  width : 100%;
  min-height : 40px;
  line-height : 40px;
}

.formline.spaced {
  margin-top : 20px;
  margin-bottom : 20px;
}

.inputbox > input:not(.checkbox), .inputbox > select, .formline input:not(.checkbox), .formline select, .formline textarea, .submitline input:not(.checkbox) {
  width: 100%;
}

.adress_second {
  width : 45%;
  margin : 20px 5% 0 0;
  background : #F4F4F4;
  padding : 20px;
  float : left;
}

.adress_second .content_adress {
  min-height : 122px;
}

.submitline {
  margin-top : 10px;
  text-align : center;
}

.submitline .submitbox {
  display : inline-block;
  max-width : 440px;
}

form {
  margin:0;
}
select, textarea, input[type="text"], input[type="password"], input[type="number"],
input[type="tel"], input[type="email"],
input.case, select.normal, textarea.normal {		/*** configuration general ***/
  color: #888;
  font-size: 15px;
  outline: -webkit-focus-ring-color auto 0px;
  outline: none;

  /*padding : 13px 17px;;*/
}
input.case,
input[type=checkbox]  {		/*** configuration cases à cocher ***/
  background-color: transparent;
}

input[type="submit"],
input[type="button"],
button.submit {
  position: relative;
  background:$btn-primary-bgcolor !important;
  color: white !important;
  border: none;
  border-color: $btn-primary-bordercolor;
  border-radius: 5rem;
  font-size: 2rem;
  padding: 1rem 2.2rem;
  font-weight: bold;
  z-index: 1;

}

select:focus, .custom-select:focus, textarea:focus, input[type="text"]:focus, input[type="password"]:focus,
input[type="tel"]:focus, input[type="email"]:focus,
.focus, .focus2, .focus3,
select.focus,
textarea.focus, textarea.focus2  {
  /*** Mise en avant des champs en cours d'utilisation - onfocus="this.className='focus';" ***/
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(210, 211, 209, 0.5);
  border-color: #0082b8
}
textarea{
  resize: none;
}
select, textarea, input[type="text"], input[type="password"], input[type="number"],
input[type="tel"], input[type="email"],
.search, .fpart, .fpart2 {
  /*** Retour à  l'état normal aprés l'utilisation - onblur="this.className='normal';" ***/
  padding-left: 8px;
  margin:1px 1px;
}
label{
  margin-bottom: 0;
  font-weight: 500;
  font-size: 1.6rem;
  color: $color4;
  line-height: 1;
}
select, textarea, input[type="text"], input[type="password"], input[type="number"],
input[type="tel"], input[type="email"] {
  /*** bordure des champs ***/
  font-family: 'Roboto', sans-serif;
  border: 1px solid #DDDDDD;
  height: 44px;
}

#fContact input,
#fContact textarea {
  display : block;
  width : 100%;
  margin : 5px 0;
  line-height : 24px;
}
#fContact textarea {
  height: 150px;
}
#fContact {
  text-align : center;
  max-width: 800px;
}

#fContact .row {
  margin-bottom : 0;
}

#fContact .button {
  margin-top : 15px;
}

textarea.normal, textarea.focus, textarea.focus2
  /*** taille textarea ***/ {

  height:8em;
  overflow:auto; /* enleve la barre de défilement dans IE */
}

/* special radio / checkbox rules */

.radiobox, .checkbox { position:relative; }

input[type=checkbox],
input[type=radio]{
  opacity : 0;
  position:absolute;
  top : 50%;
  margin-top : -9px;
  width : 30px;
}

input[type=checkbox] + label > span.checkitem,
input[type=radio] + label > span.radioitem {
  width : 16px;
  height : 16px;
  display:block;
  position:absolute;
  top : 50%;
  margin-top : -9px;
  left : 0px;
}

input[type=checkbox] + label > span.checkitem {

  background-color:#fff;
  border:1px solid rgba(0,0,0,0.2);
}

input[type=radio] + label > span.radioitem {
  background-color:#fff;
  border:1px solid rgba(0,0,0,0.2);
  border-radius:9px;
  color:#534d40;
}

input[type=checkbox]:checked + label > span.checkitem:before {
  content:" ";
  width : 3px;
  height : 12px;
  display : block;
  position : absolute;
  z-index:1;
  top : 1px;
  right : 4px;
  border-radius:0;
  background-color:#534d40;

  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);

  -ms-transform-origin: 50% 50% 0;
  -webkit-transform-origin: 50% 50% 0;
  transform-origin: 50% 50% 0;
}
input[type=checkbox]:checked + label > span.checkitem:after {
  content:" ";
  width : 3px;
  height : 7px;
  display : block;
  position : absolute;
  z-index:1;
  bottom : 1px;
  right : 4px;
  border-radius:0;
  background-color:#534d40;

  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);

  -ms-transform-origin: -70% 130% 0;
  -webkit-transform-origin: -70% 130% 0;
  transform-origin: -70% 130% 0;
}

input[type=radio]:checked + label > span.radioitem:before {
  /*background-position : 0 -18px;*/
  background-color:#333;
  border-radius:9px;
  width : 12px;
  height : 12px;
  display:block;
  position:absolute;
  top : 50%;
  margin-top : -6px;
  left : 50%;
  margin-left : -6px;
  content:'';
}

input[type=checkbox] + label,
input[type=radio] + label {
  line-height : 18px;
  padding-left : 25px;
  display:block;
  cursor:pointer;
  position:relative;
}

.formline input[type=checkbox] + label,
.formline input[type=radio] + label {
  line-height : 1.2;
  vertical-align : middle;
  display : inline-block;
}