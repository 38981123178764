/* Bootstrap 4
.col- (extra small devices - screen width less than 576px)
.col-sm- (small devices - screen width equal to or greater than 576px)
.col-md- (medium devices - screen width equal to or greater than 768px)
.col-lg- (large devices - screen width equal to or greater than 992px)
.col-xl- (xlarge devices - screen width equal to or greater than 1200px)
*/

$breakpoint-xs: 575px;
$breakpoint-sm: 767px;
$breakpoint-md: 991px;
$breakpoint-lg: 1199px;
$breakpoint-xl: 1499px;