#home #header,
#header_home {
  background: url("../img/theme/img-header.png") no-repeat top center #fff;
  background-size: cover;
}
#header_home {
  position: relative;
  background-size: cover;
  background-position: bottom right;
}

#feuilles-home {
  background: url("../img/theme/feuilles-home.svg") no-repeat center;
  height: 277px;
  width: 100%;
  position: absolute;
  bottom: -80px;
}

#header_home_top {
  background: url("../img/theme/img-header-top.png") no-repeat top center #fff;
}
#header_home_top {
  position: relative;
  background-size: cover;
  background-position: bottom right;
}

#header_home {
  .texte_header_home {
    text-align: center;
    font-size: 2.2rem;
    color: $color2;
    p {
      padding: 0;
      margin:0;
    }
  }
}

#header #logo {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 15px;
}

.btn-home-catalogue {
  z-index: 100;
}

.arrondi-section {
  background: $color2;
  height:100px;
  width:100%;
  border-radius: 50% 50% 0 0;
}

.page_cms .container .cms {
  min-height: 400px;
}

  /* BLOC PRESENTATION */
#presentation {
  margin-top: 80px;
  margin-bottom: 80px;
  img {
    position: relative;
    border: solid 6px $color3;
  }
  img:after {
    position: absolute;
    bottom: 6px;
    left: -8px;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    border:solid 1px #000;
    z-index: 0;
  }
}

/* SCHEMA */
#schema {
  color: #fff;
  padding-bottom: 150px;
  h2, .h2, h3, .h3 {
    color: #fff;
    text-align: center;
    position: relative;
    z-index: 1;
  }
  p {
    text-align: center;
    line-height: 1 ;
    margin-top: 0.5rem;
  }
  .etape-fleche1 {
    background: url("../img/theme/fleche1.png") no-repeat center right;
    /*background-size:contain;*/
  }
  .etape-fleche2 {
    background: url("../img/theme/fleche2.png") no-repeat center right;
    /*background-size:contain;*/
  }

}

@media (max-width: $breakpoint-md) {
  #header #logo {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}


@media (max-width: $breakpoint-sm) {
  #header {
    background: none;
    background-color: #ffffff;
  }
  #header_home {
    background-size: contain;
    background-position: bottom;
    background-color: #ededed;
  }
  #header_home_content .btn {
    margin-bottom: 50px;
  }
  #header_home .texte_header_home {
    /*background: rgba(255, 255, 255, 0.8);*/
    font-size: 1.8rem;
  }
  #schema {
      padding-bottom: 50px;
    .etape {
      margin-bottom: 6rem;
    }
  }
}
