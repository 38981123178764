.compl_producteur_content {
  height:100%;
  line-height: 1.2;
}
.marque_description {
  text-align: center;
  margin-bottom: 5rem !important;
}
.compl_producteur_content .marque_img {
  text-align: center;
  background-color:#fff;
}

.marque_coordonnees p { margin-bottom:0.3em; }

.liste_producteur {

  padding:0;

  > li {
    margin-bottom:30px;
    list-style-type: none
  }
  > li:last-child {
    margin-bottom:10px;
  }
  li header a {
    display:block;
    margin:auto;
  }
  header figure img {
    display:inline-block;
    margin:auto;
    -webkit-filter: drop-shadow(5px 5px 5px #fff);
    filter:         drop-shadow(5px 5px 5px #fff);
  }
  li header a:hover,
  li header a:focus {
    opacity:0.8;
  }
  li header a:hover .t-producteur,
  li header a:focus .t-producteur {
    background-color: $color4;
  }
  li header a figure {
    display:block;
    background-color:#e7e7e7;
    text-align: center;
    background-image: url('../img/theme/bg_generique_marque.jpg');
    background-size: cover;
    background-position: center center;
    min-height: 250px;
    line-height: 245px;
  }
  .no-image-producteur {
    font-weight: bold;
    color: #ccc;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1;
  }
  .description {
    margin:0.8em auto;
    font-size:14px;
    color: $color2;
    line-height: 1.2;
  }
  .h3 {
    color: #fff;
  }
}

.home_cat_col .liste_producteur li header a figure {
  min-height: 98px;
  line-height: 98px;
}

.t-producteur,
.home_cat_col .t-producteur {
  font-size:16px;
  color:#fff;
  font-weight: normal;
  background-color: $color2;
  margin:auto;
  min-height: auto;
  line-height: normal;
  padding: 8px 10px;
  order:2;
  text-align: left;
}
#marques .t-producteur {
  min-height:56px;
  line-height: 36px;
}

.link_producteur {
  font-size: 12px;
  color: $color3;
  vertical-align: middle;

}
.link_producteur:before {
  content:'';
  display:inline-block;
  margin-right:10px;
  width:70px;
  border-bottom: 1px solid $color3;
  vertical-align: middle;
}
.link_producteur_all {
  display:inline-block;
  overflow: hidden;
  background-color: $color3;
  color: #fff;
  padding: 3px 10px 0px 10px;
  font-size:12px;
  box-shadow: 0 0 0 0 $color3;
}
.link_producteur_all:hover,
.link_producteur_all:focus {
  background-color: #fff;
  color: $color3;
  box-shadow: 0 0 0 1px $color3;
}
.link_producteur_all .icon-producteur {
  display:inline-block;
  font-size:28px;
  margin-right:5px;
  margin-bottom: -4px;
}
