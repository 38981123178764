/* Menu access header */

.menu_acces {

  .icon:before {
    position: relative;
    z-index: 1;
  }

  .icon:not(.icon-close):after {
    position: absolute;
    top: -6px;
    /*right: 18px;*/
    right:calc(100% - 73px);
    display: block;
    content: '';
    background: #EDCCBC;
    width: 50px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 50px;
    height: 50px;
    z-index: 0;
  }
  ul {
    margin:auto;
    padding:0;
  }
  ul li {
    margin:auto;
    padding:0;
    position:relative;
    display:inline-block;
    vertical-align: top;
    margin-top: 0;
    margin-bottom: 0;
    a, a > span {
      display:block;
      text-align: center;
    }
    a {
      width: 90px;
      line-height: 1;
      .icon {
        margin-bottom: 3px;
      }
      .text {
        line-height: 1; font-size:1.6rem;color:$color2; font-family: 'Roboto Condensed';
      }
    }
    a:hover, a:focus {
      opacity:0.7;
    }
  }
}

.menu_a_membre .logout {
  position:absolute;
  z-index: 1;
  top:-2px;
  right:50%;
  transform: translateX(50%);
  margin-right:-22px;
  width:auto;
}

#header .col-right .block_link .menu_a_membre {
  .logout .icon {
    font-size: 12px;
    color: $color2;
  }
  .logout:hover .icon {
    color: $color3;
  }
  .title {
    font-size: 11px;
    color: #474646;
    font-family: 'Roboto Condensed', sans-serif;
  }
}


/* FIN Menu access header */


/* Menu header global */

#header .col-right .block_link {
  height: 10rem;
  line-height: 10rem;

  #panierheader {
    position: relative;
  }

  .nb_article {
    position: absolute;
    left: -8px;
    top: -2px;
    background-color: $color3;
    /*border: 1px solid #e95101;*/
    border-radius: 50%;
    width: 21px;
    height: 21px;
    line-height: 21px;
    color: #fff;
    font-size: 14px;
    text-align: center;
  }

  .header_right {
    width:100%;
    text-align: center;
  }

  .icon {
    color: $color2;
    font-size:48px;
    z-index: 1;
  }

  .icon-panier {
    font-size: 30px;
  }


}

#header .col-right .block_link #espaceclientheader,
#header .col-right .block_link #panierheaderTop,
#header .col-right .block_link > div{
  display: inline-block;
  vertical-align: middle;
}
#header .col-right .block_link #panierheader .icon-panier:before {
  position: relative;
  z-index: 1;
}
#header .col-right .block_link #panierheader .icon-panier:after {
  /*position: absolute;
  top: 0;
  left: 0;
  display: block;
  content: '';
  background: #edccbc;
  width: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  height: 30px;
  z-index: 0;
  */
}

.col-listing-panier,
.col-listing-panier a {
  color: #fff;
}
.col-listing-panier {
  background-color:$color4;
  font-size:13px;
}
.col-listing-panier .title {
  font-size:18px;
}
.panier_close {
  position:absolute;
  z-index: 5;
  bottom:-64px;
  right:10px;
  display: block;
  width:28px;
  height:28px;
  line-height: 28px;
  text-align: center;
  background-color: rgba(255,255,255,1);
  border-radius: 20px;
  color:$color2;
  font-size:10px;

  -moz-box-shadow: 0px 0px 4px rgba(0,0,0,0.3);
  -webkit-box-shadow:  0px 0px 4px rgba(0,0,0,0.3);
  box-shadow:  0px 0px 4px rgba(0,0,0,0.3);
}

.col-search { text-align:center; }

/* FIN Menu header global */



/* Menu header panier */

#panierheaderTop {

  padding: 10px 10px 10px 20px;

  .col-panier-item {

    position: relative;
    border: 1px solid $color2;
    color: $color2;
    border-radius: 50px;
    padding: 10px 20px;

    &:hover, &:focus {
      border: 1px solid $color3;
      color: #fff;
      background-color: $color3;
    }
    &:hover .icon-panier {
      color: #fff;
    }
    &:hover .nb_article {
      background: $color2;
    }
    &:hover .icon-panier:after {
      background: $color3 !important;
    }
  }
}

#panierheader {

  padding:10px 10px 10px 20px;

  a:hover {
    color: inherit;
    opacity: 1;
  }

  p {
    margin:0.1em auto;
    display:inline-block;
    min-width: 105px;

  }

  .rb_total {
    font-size: 20px;
  }
}


.hide-panier #menu-desktop,
.hide-panier #contenu_body { flex:100%; }

.hide-panier #col-listing-panier,
.hide-panier #contenu_panier {

  opacity:0;
  flex:0;

  overflow:hidden;
}
#contenu_panier {
  padding-left: 10px;
  padding-right: 10px;
}
.none-panier.hide-panier #col-listing-panier,
.none-panier.hide-panier #contenu_panier {
  display:none;
}
.hide-panier .panier_close {
  right:-12px;
  width: 35px;
}
.hide-panier .panier_close .icon {
  display:inline-block;
  overflow: hidden;
  width:5px;
  margin-bottom: -1px;
  margin-right: 7px;

  transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
}

/* listing panier */

body:not(.page_home) #header.sticky + #contenu{
  margin-top: 175px;
}
body:not(.page_home) #header.sticky + #contenu .col-sticky {
  top: 185px;
}
body:not(.page_home) #header.sticky + #contenu #listing_panier_article {
  max-height: calc(100vh - 390px);
}

.listing_panier_content {
  background-color: #eceee9;
  margin: auto -10px;
  padding:0;
}
.listing_panier {
  padding:0;
  margin:auto;
}
.listing_panier li {
  display: -ms-flexbox;
  display:flex;

  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;

  -ms-flex-direction : row;
  flex-direction: row;

  -webkit-box-pack : justify;
  -ms-flex-pack : justify;
  justify-content: space-between;

  color:#787676;
  font-size:14px;
  border-bottom: 1px solid #d6d6d4;
  padding:10px;

  &:last-child {
    border:none;
  }
}
.listing_panier + .listing_panier {
  border-top: 1px solid #d6d6d4;
}
.col-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  margin:auto -10px;
  padding: 0 10px;
}
#listing_panier_article {
  max-height: calc(100vh - 440px);
  overflow-y: auto;
  overflow-x: hidden;
}

.listing_panier .panier_desc { 
  font-family: 'Roboto Condensed', sans-serif; 
  line-height: 1.1; 
}

.listing_panier .panier_img { 
  margin-right:10px; 
  width: 45px;
  text-align: center;
  background: #fff;
}

.listing_panier .panier_bloc_desc { width:100%; }
.listing_panier .panier_bloc {
  display: -ms-flexbox;
  display:flex;

  -webkit-box-pack : justify;
  -ms-flex-pack : justify;
  justify-content: space-between;

  width:100%;

  -ms-flex-align: center;
  align-items: center;
}

.listing_panier .panier_price {
  font-size:14px;
  color:$color2;
  font-weight: bold;
}

.listing_panier .panier_total .panier_price {
  font-size: 17px;
}

.listing_panier .quantity_container .qt_manage {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  bottom:auto;
  right:auto;
  border:1px solid #d7dccf;
  border-radius:50%;
  background-color:#fff;
  width:29px;
  height:29px;
  line-height:26px;
  text-align: center;
  font-size: 20px;
  font-weight: 100;
}

.qt_del .icon {
  color:#000;
  font-size:16px;
  text-rendering: optimizelegibility;
}
.qt_down .icon,
.qt_del .down {
  display:none;
}
.qt_del .icon {
  display:inline-block;
}

.qt_up .max,
.qt_max .up {
  display:none;
}
.qt_max .max {
  display:inline-block;
  font-size: 10px;
  position: relative;
  top: -4px;
}

.panier_add.quantity_container input {
  border : 1px solid #ddd;
  border-radius : 2px;
  background : #ededed;
  line-height : 32px;
  height : 32px;
  padding : 3px 5px;
  width : 80px;
  text-align : center;
  margin: 0;
}

.panier_add {
  .qt_manage {
    position: absolute;
    right: 12px;
    width: 0;
    height: 0;
    cursor: pointer;
    opacity: 0.7;

    -o-transition: opacity .1s ease-in;
    -webkit-transition: opacity .1s ease-in;
    -moz-transition: opacity .1s ease-in;
    transition: opacity .1s ease-in;
  }
  .qt_manage:hover {
    opacity : 1;
  }
  .qt_up {
    bottom : 40px;
    border-width : 0 6px 10px 6px;
    border-color : transparent transparent #5a8622 transparent;
    border-style : solid;
  }
  .qt_up,
  .qt_down {
    right : calc( 50% - 6px );
  }
  .qt_down {
    bottom : -18px;
    border-width : 10px 6px 0 6px;
    border-color : #b93f19 transparent transparent transparent;
    border-style : solid;
  }
}


.listing_panier .panier_add.quantity_container input {
  display: inline-block;
  vertical-align: middle;
  border: none;
  background: none;
  width: 25px;
  height: auto;
  padding: 0;
  box-shadow: none;
}
.listing_panier .panier_bloc .item1 {
  width: 60%;
  text-align: right;
}
.frais_port .item2 { color: #698f20; }
.panier_action > a { width:100%; color:#474646; }
.panier_action > a:hover,
.panier_action > a:focus { opacity:0.8; }
.panier_action .icon { font-size:16px; color:#333; vertical-align: middle; }
.panier_action .item3 { text-align:left; width:calc( 100% - 28px );  }

.button.bt_panier_buy {
  font-size:20px;
  width:100%;
  margin-top:5px;
  border-radius: initial;
  padding: 8px 3px;
}
/* fin listing panier */







/*** RESPONSIVE **/
@media (max-width: $breakpoint-lg) {

  #header .col-right .block_link {
    .icon {
      font-size: 35px;
    }
    .icon:after {
      width: 32px;
      height: 32px;
      right: calc(100% - 65px);
    }

  }
}

@media (max-width: $breakpoint-md) {

  #header {
    height: 85px;
    justify-content: end;
  }

  #header .col-right .block_link {
    height: 8.5rem;
    line-height: 8.5rem;
    /*float: right;*/
    width: auto;
    margin-right: 1rem;

    .icon:after {
      right: calc(100% - 50px);
    }
  }

  /* sticky */
  #header.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    height: 85px;
    background-color: #ffffff;
    margin-top: 0;
    width: 100%;
  }
  #bandeau.sticky #menu_launcher {
    position: fixed;
    top: 85px;
  }
  /* Fin sticky */

  .rb_total {
    display:none !important;
  }

  .icon {
    font-size: 30px;
  }

  .icon-close {
    font-size: 10px;
  }

  .icon-panier {
    margin-right: 0 !important;
  }

  .contenu_panier {
    position:fixed;
    z-index: 1001;
    top:125px;
    left:0;
    right:0;
  }

  .hide-panier .panier_close {
    width: 45px;
  }

  .menu_acces ul li a {
    width: 70px;
  }

  .col-listing-panier {
    display: none;
  }
  .listing_panier_content {
    overflow-y: auto;
    height: calc(100vh - 120px);
  }
  .panier_close {
    bottom: -75px;
  }

  #listing_panier_article {
    max-height: none !important;
    overflow-y: initial;
    overflow-x: initial;
  }

}

@media (max-width: $breakpoint-sm) {

  .menu_acces {
    .icon::after, .text {
      display: none !important;
    }
    ul li a {
      width: auto;
    }
  }

  /* header */
  .menu_acces ul li a .text {
    display: none;
  }

  #header,
  #header .col-right .block_link {
    height: 50px;
  }

  #header .col-right .block_link {
    line-height: 50px;

    .icon-panier {
      font-size: 20px;
      margin-bottom: 5px;
    }

  }

  #panierheaderTop {
    padding: 0px;
  }

  #header {
    background-color: #D4EAEF;
  }

  body:not(.page_home) #header.sticky + #contenu {
    margin-top: 0;
  }

  .rb_total { display:none !important; }

  .col-content-pattern { background: none; }
  #panierheaderTop .col-panier-item { padding: 3px 7px !important; }
  #header .col-right .block_link .nb_article {
    width: 17px;
    height: 17px;
    line-height: 17px;
    font-size: 10px;
  }
  #header .col-right .block_link #panierheader { font-size: 24px; }
  #header .block_link .menu_acces .icon { font-size: 20px; }
  #header .col-right .block_link {  margin-right: auto; }

  #logo img,
  #header.sticky .logo_sticky {
    max-width:50px;
    margin-left: 0px;
    margin-bottom: 8px;
  }

  #header #logo {
    margin-left:10px ;
  }

  /* sticky */
  #header.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    height: 50px;
    background-color: #ffffff;
    margin-top: 0;
    width: 100%;
  }
  #bandeau.sticky #menu_launcher {
    position: fixed;
    top: 50px;
  }
  /* Fin sticky */

  #header.sticky .col-right .block_link {
    height: 50px;
    line-height: 50px;
  }

  #header .col-right.col-panier .block_link .icon {
    margin-right: auto;
  }

  #panierheader { padding: 0 !important;  }

  /* panier header */
  .contenu_panier {
    position:fixed;
    z-index: 1000;
    top:90px;
    left:0;
    right:0;
  }
  #header.sticky + #contenu > .contenu_panier {
    top:90px;
  }

  #header.sticky > div:first-child #logo {
    left: 53px;
    top: 0px;
    display:block;
  }
  .listing_panier_content {
    overflow-y: auto;
    height: calc(100vh - 85px);
  }
  .panier_close {
    bottom: -75px;
  }

}

.width_full {
  width:100%;
  padding: 0;
}

@media (max-width: $breakpoint-xs) {
  /* sticky */
  #header.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    height: 50px;
    background-color: #ffffff;
    margin-top: 0;
    width: 100%;
  }
  #bandeau.sticky #menu_launcher {
    position: fixed;
    top: 50px;
  }
  /* Fin sticky */

}

@media (min-width: 992px) {
  .width_body {
    -ms-flex: calc(100% - 235px);
    flex: calc(100% - 235px) !important;
    width: calc(100% - 235px) !important;
  }
  .width_panier {
    -ms-flex: 235px;
    flex: 235px;
    width:235px;
  }
  /* sticky */
  body:not(.page_home) #header.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    height: 153px;
    background-color: #ffffff;
    margin-top: 0;
    width: 100%;

    #logo {
      max-width:250px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  /* Fin sticky */
}

