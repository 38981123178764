.fancybox-container {
  h2 {
    font-size: 2.5rem;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: inline-block;
    }
  }
}