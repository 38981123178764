#rassurance {

  color: $color2;
  margin-left: -15px;
  margin-right: -15px;

  #rassurance_content {
    position: relative;
    z-index: 1;
  }
  a {
    position: relative;
    z-index: 1;
    padding-top: 10px;
    color: $color2;
  }
  a:hover .icon {
    transform: translateY(-10px);
  }
  .icon:before {
    position: relative;
    z-index: 1;
  }
  .icon {
    position: relative;
    z-index: 1;
    font-size: 10rem;
    color: $color2;
    text-align: center;
    transition: all .3s ease-in;
  }
  a:after {
    position: absolute;
    z-index: 0;
    content: '';
    top:0;
    left: calc((100% - 150px)/2);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: #EDEDED;
  }
  .deco-feuille-left {
    position: absolute;
    top: 10px;
    left: 0;
    height: 100%;
    width: 50%;
    background: url("../img/theme/feuillesgrises1.png") no-repeat;
  }
  .deco-feuille-right {
    position: absolute;
    top: 10px;
    right: 0;
    height: 100%;
    width: 50%;
    background: url("../img/theme/feuillesgrises2.png") no-repeat;
  }
}

@media (max-width: $breakpoint-sm) {
  #rassurance a::after {
    display: none;
  }
  #rassurance {
    h4,p {
      font-size: 1.6rem;
    }
  }
}