h1, h2, h3, h4, h5, h6,
.rassurance,
.text,
.t1, .t2, .t3,
.title,
.desc,
.titre,
.stitre,
.sstitre
.news_content {
  word-wrap: normal;
  hyphens: none;
  line-height: 1;
}

h1,
.h1,
.titre
{
  position: relative;
  color: $color2;
  font-size: 5rem;
  font-weight: 700;
  margin: 1rem 0 0.5rem;
  line-height: 1;
}

h2,
.h2,
.stitre
{
  font-size: 3rem;
  color: $color2;
  margin: 0.6em 0;
  font-weight: bold;
  line-height: 1;
}

h3,
.h3,
.sstitre {
  font-size: 2.4rem;
  color: $color2;
  margin: 0.6rem 0;
  font-weight: bold;
  line-height: 1;
}

h4,
.h4,
.ssstitre
{
  font-size: 1.8rem;
  color: $color2;
  margin: 0.6rem 0;
  font-weight : bold;
  line-height: 1;
  text-transform: uppercase;
}

@media (max-width: $breakpoint-md) {
  body{
    font-size: 1.5rem;
  }

  .h1,
  .titre,
  h1 {
    font-size: 4rem
  }

  .h2,
  .stitre,
  h2 {
    font-size: 3.5rem;
    &::after {
      margin-top: 10px;
    }

  }

  .h3,
  .sstitre,
  h3 {
    font-size: 2rem
  }

  .h4,
  .ssstitre,
  h4 {
    font-size: 2rem
  }
}

/* Ajustements title page cms */
.cms {
  h1 {
    margin-bottom: 6rem;
  }
  h3, h2, h4 {
    margin-top: 5rem;
  }
}


/* CMS ADMIN Surcharge css pour desactiver style sur ADMIN */
body[class^="breakpoint"],
body[class*="breakpoint"] {

  .btn.btn-default.pull-right + h1,
  .form-actions + h1,
  .grid.simple h3,
  #choose-type h3 {
    color: #11708F;
    font-size: 3rem;
    font-family: inherit;
    font-weight: inherit;
    text-transform: none;
  }

}