/* Panier */

/* YS */
.adress-liv-column {
        flex:0 0 100%;
        max-width:100%;
	//display: flex;
	display:inline-block;
}

.adress-liv-column-first {
	justify-content: center;
	display: flex;
	max-width: 100%;
}

.toto {
	margin-left: auto;
	margin-right: auto;
}

.toto2 {
        margin-left: auto;
        margin-right: auto;
}

/* FIN YS */

/* step */
.step-panier {
  list-style: none;
  margin:auto;
  padding:0;
  margin-bottom: 5rem;
  li {
    flex: 1;
    .step-panier-item {
      flex: 1;
      background-color: #f1f1f0;
      border-radius: 4rem 0 0 4rem;
      color: #ccc;

      .circle,
      .white_arrow_after {
        width: 4rem;
        height: 4rem;
        background-color: #ccc;
        color: #fff;
      }
      .white_arrow_after {
        display:flex;
        background-color: $color2;
        border-radius: 4rem 0 0 4rem;
        margin-left:2rem;
      }
      .text {
        flex: 1;
      }
      &:hover, &:focus {
        opacity: 1;
      }
    }
    &:last-child .step-panier-item {
      border-radius: 0 4rem 4rem 0;
    }

  }
  a {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    font-weight: bold;
    color: $color2 !important;
    .circle {
      background-color: $color4 !important;
    }
  }
}

/* Panier recap commande */
#panier-recap-content {
  background-color: #f1f4f6;
  font-size: 1.4rem;
  figure {
    text-align: center;
    background: #fff;
    width: 50px;
    margin: auto;
  }
  .product_line_global
  {
    font-size: 1.8rem;
  }
}



.panier {
  .panier-listing {
    .delete {
      font-size: 1.8rem;
      cursor: pointer;
    }
    .recap_article_content {

      .line-caddy {

        .item-caddy {
          font-size: 1.4rem;
        }

        .item1 {

          figure {

            margin-right: 10px;
            text-align: center;
            background: #fff;
            width: 50px;
            margin:auto;

          }
          .label_content {
            font-size: 1.3rem;
            .label {
              min-width: 7.5rem;

              @media (max-width: $breakpoint-xl) {
                min-width: auto;
              }
            }
          }
        }
        .item2 {
          .quantity {
            .qt_block {
              .quantity_container {
                font-size: 1.6rem;
                color: $color2;

                .inpQtyCart {
                  width: 45px;
                  text-align: center;
                  color: $color2;
                  border: none;
                  border-left: 1px solid $color2;
                  border-right: 1px solid $color2;
                  padding: 0 0.5rem

                }
                .qt_manage {
                  font-size: 2.3rem;
                  margin: auto 0.5rem;
                  cursor: pointer;
                }
              }
            }
          }
        }
        .item3 {
          .icon {
            cursor: pointer;
          }
        }
      }
    }

  }
}

.panier-recap {
  .sticky_block {
    .tableau-panier {
      .panier-recap-content {

      }
      .remise-recap-content {
        .panierCodesRemises {
          form {
            .bdr_code {
              font-weight: 500;
              flex:1;
              width:auto;
              padding: 10px 10px 10px 10px;
            }
            .button { min-width: auto; }
          }
        }
      }
    }
    .panier_action {

    }
  }
}


/* Message alert */
.alert-content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
.circle {
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.circle-alert {
  width: 2.4rem;
  height: 2.4rem;
  font-weight: 500;
  background-color: #A39E9B;
  color: $color1;
  font-size: 1.5rem;
}

/* spé */
.alert-success .circle-alert {
    background-color: #155724;
}
.alert-error .circle-alert {
    background-color: #D54A16;
}
.alert-message .circle-alert {
    background-color: #0c5460;
}
.alert-danger .circle-alert {
    background-color: #721c24;
}
.alert-warning .circle-alert {
  background-color: #856404;
}


body .border6 {
  border: 1px solid #e6e9ea;
}

body .bg3 {
  background-color: #f9f9f9;
}



/* Tunnel commande */

.liste_transporteur li,
.choix_mode_paiement li {
  display: block;
  min-height: 50px;
  line-height: 50px;
  margin: 0;
  padding: 20px;
  text-align: left;
  background: #f3f3f3;
  border-radius: 3px;
}


@media (max-width: $breakpoint-sm) {

  /* Step */
  .step-panier {
    li {
      margin-bottom: 1rem;
    }
    li .step-panier-item,
    li:last-child .step-panier-item {
      -webkit-border-radius: 4rem;
      -moz-border-radius: 4rem;
      border-radius: 4rem;
    }
  }
}
