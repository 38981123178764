/**** pagination ****/
.navliste
{
  text-align:right;
  font-size:90%;
  margin:1em 0;
}
.navliste ul
{
  list-style:none;
  margin:0;
}
.navliste li
{
  display:inline;
  margin:0;
}
.navliste a
{
  display:inline-block;
  border : 1px solid #E0E0E0;
  padding:2px 8px;
  margin:1px 0.1em;
  -moz-border-radius:3px;
  -webkit-border-radius:3px;
  border-radius:3px;
  line-height:1.6em;
  width : 38px;
  height : 28px;
  line-height : 22px;
  text-align : center;
  color : #000000;
  font-weight : 700;
  transition : all 0.2s ease-in-out;
}
.navliste a:hover,
.navliste a:focus
{
  background-color:#999;
  border : 1px solid #999;
  color : #ffffff;
}
.navliste .current a,
.navliste .current a:hover
{
  background-color:#E0E0E0;

}

span.precedent,
span.suivant
{
  display:inline-block;
  background-color:#eee;
  color:#888;
  padding:2px 8px;
  margin:1px 0.1em;
  -moz-border-radius:3px;
  -webkit-border-radius:3px;
  border-radius:3px;
  line-height:1.6em;
}

.precedent
{
  padding-right:1em;
}
.suivant
{
  padding-left:1em;
}