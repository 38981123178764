#chemin
{
  position:relative;
  z-index:2; /* passe devant colonne */
  margin: 0; /* ie7 ie6*/
  overflow : hidden;
  min-height : 40px;
  line-height : 1.1;
  margin-top: 4rem;

  p {
    margin:0;
  }
  .item {
    float : left;
    display : block;
    position : relative;
  }
  .bread_link {
    color: #787676;
    font-size : 14px;
    height : 40px;
    margin-right : 0px;
  }
  .item .bread_link:after  {
    content: " / ";
    display: inline-block;
    z-index: 2;
    font-size: 15px;
    padding: 0 10px;
  }
}

#chemin .item:last-child {
  .bread_link:after,
  .bread_link:before {
    display: none;
  }
}

#chemin a:hover,
#chemin a:focus {
    text-decoration:none;
}